import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  ListItem,
  Chip,
} from '@material-ui/core';
import CardTemplate from './CardTemplate';

const AddBusiness = ({ stage, prevStage, skipStage, nextStage, values, businessNames, selectedName, handleMultipleChange }) => {
  return (
    <CardTemplate
      stage={stage} 
      minStage={5}
      stageId="#stage5"
      stageAvatar="5" 
      stageTitle="ABN Lookup"
      subHeader="Question 5 of 9"
      prevStage={prevStage}
      skipStage={() => skipStage(9)}
      nextStage={nextStage}
      nextTitle="Add"
    >
        <Typography variant="body1">Which business would you like to add {values.first + ' ' + values.last}?</Typography>
        <ul
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: '0.5',
            margin: '0',
          }}
        >
          {businessNames && businessNames.map((data) => {

            return (
              <ListItem key={data} style={{ width: 'auto' }}>
                <Chip
                  label={data}
                  onClick={() => handleMultipleChange(data)}
                  variant={selectedName.includes(data) ? 'default' : 'outlined' }
                  color={selectedName.includes(data) ? 'primary' : 'default' }
                />
              </ListItem>
            );
          })}
        </ul>
    </CardTemplate>
  );
};

AddBusiness.propTypes = {
  stage: PropTypes.number,
  prevStage: PropTypes.func,
  skipStage: PropTypes.func,
  nextStage: PropTypes.func,
  values: PropTypes.any,
  businessNames: PropTypes.any,
  selectedName: PropTypes.any,
  handleMultipleChange: PropTypes.func
};

AddBusiness.defaultProps = {
};

export default AddBusiness;
