import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Input,
  Typography,
} from '@material-ui/core';
import CardTemplate from './CardTemplate';

const Name = ({ stage, prevStage, nextStage, values, errors, touched, handleChange, handleBlur }) => {
  return (
    <CardTemplate
      stage={stage} 
      minStage={2}
      stageId="#stage2"
      stageAvatar="2" 
      stageTitle="Name"
      subHeader="Question 2 of 9"
      prevStage={prevStage}
      nextStage={nextStage}
      nextStageDisabled={values.first == ''}
      nextTitle="Save"
    >
      <FormControl style={{ display: 'inherit' }}>
        <Typography variant="body1">What&apos;s your name?</Typography>
        <Input type="text" placeholder="First"
          name="first"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.first}
          style={{ width: '100%' }} />
        {errors.first && touched.first && errors.first}
        <Input type="text" placeholder="Last"
          name="last"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.last}
          style={{ width: '100%' }} />
        {errors.last && touched.last && errors.last}
        <Typography variant="subtitle2" style={{ fontSize: 'small', marginTop: '0.4em' }}>Name: {values.first + ' ' + values.last} </Typography>
      </FormControl>
    </CardTemplate> 
  );
};

Name.propTypes = {
  stage: PropTypes.number,
  prevStage: PropTypes.func,
  nextStage: PropTypes.func,
  values: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func
};

Name.defaultProps = {
};

export default Name;
