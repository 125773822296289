import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import mainStyle from "../../style/default";
import axios from 'axios';
import Welcome from './cards/Welcome';
import Email from './cards/Email';
import Name from './cards/Name';
import Promote from './cards/Promote';
import ABN from './cards/ABN';
import AddBusiness from './cards/AddBusiness';
import About from './cards/About';
import OperatingHours from './cards/OperatingHours';
import Industry from './cards/Industry';
import PasswordCard from './cards/PasswordCard';
import Thankyou from './cards/Thankyou';
import apiClient from 'src/api/api';
import history from 'src/api/history';
import { useSnackbar } from 'notistack';
import store from '../../../store';
import { loginUser } from '../../../helpers';

const RegisterQuestions = ({ setIsLoading }) => {
  const [stage, setStage] = React.useState(1);
  const [stageHistory, setStageHistory] = React.useState([1]);
  const [counter, setCounter] = React.useState(1);
  const [userHistory, setUserHistory] = React.useState([]);
  const [promoteBusiness, setPromoteBusiness] = React.useState(false);
  const colors = ['#B7D4DB', '#EEEBE5', '#F2C6BD', '#FFD8C4', '#FFF2D4', '#FEFFE8', '#FFEBE3', '#FAF3DF', '#FDE3D5', '#F1D0CD', '#BDACBD'];
  const [startColor, setStartColor] = React.useState(0);
  const [callABN, setCallABN] = React.useState(true);
  const [businessNames, setBusinessNames] = React.useState([]);
  const [selectedName, setSelectedName] = React.useState([]);
  const [selectedIndustry, setSelectedIndustry] = React.useState('');
  const totalStages = 9;
  const { enqueueSnackbar } = useSnackbar();

  const selectIndustry = (data) => {
    setSelectedIndustry(data);
    nextStage();
  };

  const handleMultipleChange = (data) => {
    const pos = selectedName.includes(data);
    if (pos) {
      setSelectedName(selectedName.filter(item => item !== data));
    } else {
      setSelectedName(selectedName => [...selectedName, data]);
    }
  };

  const getColors = () => {
    let start = startColor;
    if (startColor + 1 == colors.length) {
      setStartColor(0);
    } else {
      setStartColor(startColor + 1);
    }

    let end = start + 1;
    if (end >= colors.length) {
      end = 0;
    }

    let retVal = {start: colors[start], end: colors[end]};
    return retVal;
  };

  const [colorRange, setColorRange] = React.useState(null);
  const main = mainStyle(colorRange)();

  const nextColorRange = () => {
    setColorRange(getColors());
  };

  React.useEffect(() => {
    nextColorRange();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  const getTimeRecords = () => {
    var days = ["Mon", "Tue", "Wed", "Thu", "Fri"];
    const weekDay = days.map((day) => {
      return {
        label: day,
        working: true,
        open: '09',
        close: '17',
      };
    });
    days = ["Sat", "Sun"];
    const weekEnd = days.map((day) => {
      return {
        label: day,
        working: false,
        open: '09',
        close: '17',
      };
    });
    return weekDay.concat(weekEnd);
  };

  const defaultTimeRecords = getTimeRecords();

  const [timeRecords, setTimeRecords] = React.useState(defaultTimeRecords);

  const validSchema = Yup.object().shape({
    password: Yup.string().required('Required'),
    confirmation: Yup.string().required('Required')
      .min(8, 'Passwords must be at least 8 characters in length')
      .matches(/[a-z]+/, "One lowercase character")
      .matches(/[A-Z]+/, "One uppercase character")
      .matches(/[@$!%*#?&]+/, "One special character")
      .matches(/\d+/, "One number")
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const handleRecord = (record, index) => {
    let records = timeRecords;
    records[index] = record;
    setTimeRecords([...records]);
  };

  const scrollToStage = (stageNum) => {
    var el = document.getElementById("#stage" + stageNum);
    if (el) {
      window.scroll({
        top: el.offsetTop - 50,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  const addUserHistory = (value) => {
    console.log({ value, counter, userHistory});
    setCounter(counter+1);
    setUserHistory({ ...userHistory, [counter]: {[stage]: value} });
  };

  const prevStage = () => {
    addUserHistory('prev');
    var lastStage = stageHistory.pop();
    if (lastStage > 1) {
      setStage(lastStage);
      nextColorRange();
      scrollToStage(lastStage - 1);
    }
  };

  const nextStage = (values) => {
    addUserHistory('next');
    console.log({ values });
    if (stage == 3) {
      setPromoteBusiness(true);
    }
    if (stage == 5 && values.abn) {
      getABNDetails(values.abn);
    }
    setStageHistory([...stageHistory, stage]);
    setStage(stage + 1);
    nextColorRange();
    scrollToStage(stage);
  };

  const skipStage = (toStage) => {
    addUserHistory('skip');
    if (stage == 3) {
      setPromoteBusiness(false);
    }
    setStageHistory([...stageHistory, stage]);
    setStage(toStage + 1);
    nextColorRange();
    scrollToStage(toStage);
  };

  const getABNDetails = async (abnnumber) => {
    if (callABN) {
      const guid = `${process.env.REACT_APP_ABN_GUID}`;
      const url = 'https://abr.business.gov.au/json/AbnDetails.aspx';
      const params = {
        abn: abnnumber,
        guid: guid,
        callback: 'abnCallback',
      };
  
      const response = await axios.get(url, {params});
      if (response.data) {
        eval(response.data);
      }
      setCallABN(false);
    }
  };

  // @ts-ignore
  // eslint-disable-next-line no-unused-vars
  function abnCallback(data) {
    const {AbnStatus, BusinessName} = data;

    if (AbnStatus == 'Active') {
      setBusinessNames(BusinessName);
    }
  }

  const createAccount = (values) => {
    const { email, first, last, abn, about, password, confirmation } = values;

    const createAccountData = {
      "devicename": "desktop",
      "email": email,
      "first": first,
      "last": last,
      "abn": abn,
      "about": about,
      "password": password,
      "confirmation": confirmation,
      "promoteBusiness": promoteBusiness,
      "selectedName": selectedName,
      "selectedIndustry": selectedIndustry,
      "timeRecords": timeRecords,
      "userHistory": userHistory // todo: save history in database
    };

    setIsLoading(true);
    apiClient.post('/register/questions', createAccountData).then(response => { 
      if (!response || response.status !== 200) {
        enqueueSnackbar('An error occurred creating an account with the username and/or email provided', { 
          variant: 'error',
        });
      } else {
        loginUser(response.data, store, apiClient, history);
      }
      setIsLoading(false);
    }).catch(error => {   
      // todo: handle the error value
      console.log({error});
      enqueueSnackbar('An error occurred registering you into the system.', {variant: 'error'});
      setIsLoading(false);
    });
  };

  return (
    <Grid container spacing={3} className={main.backgroundGradient}>
      <Grid item xs={12}>
        <Formik
          initialValues={{ 
            email: '', 
            first: '',
            last: '',
            abn: '',
            about: '',
            // @ts-ignore
            // eslint-disable-next-line no-undef
            password: '',
            // @ts-ignore
            // eslint-disable-next-line no-undef
            confirmation: '',
          }}
          validationSchema={validSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              createAccount(values);
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ 
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isValid,
            validateForm,
          }) => {
          return (
            <Form>
                <Welcome totalStages={totalStages} nextStage={() => nextStage(values)} />
                <Email stage={stage} nextStage={() => nextStage(values)} values={values} errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} />
                <Name stage={stage} prevStage={prevStage} nextStage={() => nextStage(values)} values={values} errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} />
                <Promote stage={stage} prevStage={prevStage} skipStage={skipStage} nextStage={() => nextStage(values)} values={values} />
                <ABN stage={stage} prevStage={prevStage} skipStage={skipStage} nextStage={() => nextStage(values)} values={values}  errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} />
                <AddBusiness stage={stage} prevStage={prevStage} skipStage={skipStage} nextStage={() => nextStage(values)} values={values} businessNames={businessNames} selectedName={selectedName} handleMultipleChange={handleMultipleChange} />
                <About stage={stage} prevStage={prevStage} skipStage={skipStage} nextStage={() => nextStage(values)} values={values} errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} />
                <OperatingHours stage={stage} prevStage={prevStage} nextStage={() => nextStage(values)} timeRecords={timeRecords} handleRecord={handleRecord} />
                <Industry stage={stage} prevStage={prevStage} skipStage={skipStage} nextStage={() => nextStage(values)} selectedIndustry={selectedIndustry} selectIndustry={selectIndustry} />
                <PasswordCard stage={stage} prevStage={prevStage} nextStage={() => nextStage(values)} values={values} errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} isValid={isValid} validateForm={validateForm} />
                <Thankyou stage={stage} prevStage={prevStage} values={values} />
            </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};
  
RegisterQuestions.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
};

RegisterQuestions.defaultProps = {
};


export default RegisterQuestions;
