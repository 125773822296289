import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@material-ui/core';

const SimpleTimeSelect = ({ label, value, handleChange }) => {
  return (
      <Select
        value={value}
        label={label}
        onChange={handleChange}
        style={{ marginTop: '4px' }}
      >
        <MenuItem value={'00'}>12 AM</MenuItem>
        <MenuItem value={'01'}>01 AM</MenuItem>
        <MenuItem value={'02'}>02 AM</MenuItem>
        <MenuItem value={'03'}>03 AM</MenuItem>
        <MenuItem value={'04'}>04 AM</MenuItem>
        <MenuItem value={'05'}>05 AM</MenuItem>
        <MenuItem value={'06'}>06 AM</MenuItem>
        <MenuItem value={'07'}>07 AM</MenuItem>
        <MenuItem value={'08'}>08 AM</MenuItem>
        <MenuItem value={'09'}>09 AM</MenuItem>
        <MenuItem value={'10'}>10 AM</MenuItem>
        <MenuItem value={'11'}>11 AM</MenuItem>
        <MenuItem value={'12'}>12 PM</MenuItem>
        <MenuItem value={'13'}>01 PM</MenuItem>
        <MenuItem value={'14'}>02 PM</MenuItem>
        <MenuItem value={'15'}>03 PM</MenuItem>
        <MenuItem value={'16'}>04 PM</MenuItem>
        <MenuItem value={'17'}>05 PM</MenuItem>
        <MenuItem value={'18'}>06 PM</MenuItem>
        <MenuItem value={'19'}>07 PM</MenuItem>
        <MenuItem value={'20'}>08 PM</MenuItem>
        <MenuItem value={'21'}>09 PM</MenuItem>
        <MenuItem value={'22'}>10 PM</MenuItem>
        <MenuItem value={'23'}>11 PM</MenuItem>
      </Select>
    );
};

SimpleTimeSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

SimpleTimeSelect.defaultProps = {
  label: 'Time',
  value: '09',
};

export default SimpleTimeSelect;
