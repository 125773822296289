import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Input,
  FormControl,
} from '@material-ui/core';
import CardTemplate from './CardTemplate';

const ABN = ({ stage, prevStage, skipStage, nextStage, values, errors, touched, handleChange, handleBlur }) => {
  return (
    <CardTemplate
      stage={stage} 
      minStage={4}
      stageId="#stage4"
      stageAvatar="4" 
      stageTitle="ABN"
      subHeader="Question 4 of 9"
      prevStage={prevStage}
      skipStage={() => skipStage(9)}
      nextStage={nextStage}
      nextTitle="Lookup"
    >
        <FormControl style={{ display: 'inherit' }}>
          <Typography variant="body1">What&apos;s your ABN {values.first + ' ' + values.last}?</Typography>
          <Input type="text" placeholder="ABN"
            name="abn"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.abn}
            style={{ width: '100%' }} />
            {errors.abn && touched.abn && errors.abn}
        </FormControl>
    </CardTemplate>
  );
};

ABN.propTypes = {
  stage: PropTypes.number,
  prevStage: PropTypes.func,
  skipStage: PropTypes.func,
  nextStage: PropTypes.func,
  values: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func
};

ABN.defaultProps = {
};

export default ABN;
