import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  TextField,
  FormControl,
} from '@material-ui/core';
import CardTemplate from './CardTemplate';

const About = ({ stage, prevStage, skipStage, nextStage, values, errors, touched, handleChange, handleBlur }) => {
  return (
    <CardTemplate
      stage={stage} 
      minStage={6}
      stageId="#stage6"
      stageAvatar="6" 
      stageTitle="About"
      subHeader="Question 6 of 9"
      prevStage={prevStage}
      skipStage={() => skipStage(9)}
      nextStage={nextStage}
      nextStageDisabled={values.about == ''}
      nextTitle="Save"
    >
      <FormControl style={{ display: 'inherit' }}>
        <Typography variant="body1">In one or two sentences, briefly describe your business</Typography>
        <TextField
          label="About"
          multiline
          rows={4}
          name="about"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.about}
          style={{ width: '100%' }} 
        />
        {errors.about && touched.about && errors.about}
      </FormControl>
    </CardTemplate>    
  );
};

About.propTypes = {
  stage: PropTypes.number,
  prevStage: PropTypes.func,
  skipStage: PropTypes.func,
  nextStage: PropTypes.func,
  values: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func
};

About.defaultProps = {
};

export default About;
