import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from '@material-ui/core';
import mainStyle from "../../../style/default";
import clsx from 'clsx';
import EnhancedAvatar from 'src/views/component/alt/EnhancedAvatar';

const CardTemplate = ({ stage, minStage, stageId, stageAvatar, stageTitle, subHeader, prevStage, prevTitle, skipStage, skipTitle, nextStage, nextStageDisabled, nextTitle, submitTitle, children }) => {
  const main = mainStyle({ showCard: stage > minStage })();

  return (
    <Card className={clsx(main.defaultCard, main.revealCard)} id={stageId}>
      <CardHeader
        avatar={
          <EnhancedAvatar name={stageAvatar} />
        }
        title={stageTitle}
        subheader={subHeader}
      />
      <CardContent>
        {children}
      </CardContent>
      <CardActions>
        {prevStage && (
          <Button type="button" color="primary" variant="text" className={main.noRightMargin} onClick={prevStage}>{prevTitle}</Button>
        )}
        {skipStage && (
          <Button type="button" color="primary" variant="outlined" className={main.noRightMargin} onClick={skipStage}>{skipTitle}</Button>
        )}
        {submitTitle ? (
          <Button type="submit" color="primary" variant="contained" className={main.noLeftMargin}>{submitTitle}</Button>
        ) : (
          <Button type="button" color="primary" variant="contained" className={main.noLeftMargin} onClick={nextStage} disabled={nextStageDisabled}>{nextTitle}</Button>
        )}
      </CardActions>
    </Card>               
  );
};

CardTemplate.propTypes = {
  stage: PropTypes.number,
  minStage: PropTypes.number,
  stageId: PropTypes.any,
  stageAvatar: PropTypes.string,
  stageTitle: PropTypes.string,
  subHeader: PropTypes.string,
  prevStage: PropTypes.func,
  prevTitle: PropTypes.string,
  skipStage: PropTypes.func,
  skipTitle: PropTypes.string,
  nextStage: PropTypes.func,
  nextStageDisabled: PropTypes.bool,
  nextTitle: PropTypes.string,
  submitTitle: PropTypes.string,
  children: PropTypes.any,
};

CardTemplate.defaultProps = {
  stageAvatar: ' ',
  prevTitle: 'Prev',
  skipTitle: 'Skip',
  nextStageDisabled: false,
  nextTitle: 'Next',
};

export default CardTemplate;
