import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import OpenCloseTime from 'src/views/component/alt/OpenCloseTime';
import CardTemplate from './CardTemplate';


const OperatingHours = ({ stage, prevStage, nextStage, timeRecords, handleRecord }) => {
  return (
    <CardTemplate
      stage={stage} 
      minStage={7}
      stageId="#stage7"
      stageAvatar="7" 
      stageTitle="Open Hours"
      subHeader="Question 7 of 9"
      prevStage={prevStage}
      nextStage={nextStage}
      nextTitle="Save"
    >
      <Typography variant="body1">What are the operating hours and days for the business?</Typography>
      <Grid container spacing={3} >
        {timeRecords.map((timeRecord, key) => {
          return (
            <Grid item xs={12} key={key}>
              <OpenCloseTime 
                record={timeRecord}
                handleRecord={(newRecord) => handleRecord(newRecord, key)}
              />
            </Grid>
          );
        })}
      </Grid>
    </CardTemplate>
  );
};

OperatingHours.propTypes = {
  stage: PropTypes.number,
  prevStage: PropTypes.func,
  nextStage: PropTypes.func,
  timeRecords: PropTypes.any,
  handleRecord: PropTypes.func
};

OperatingHours.defaultProps = {
};

export default OperatingHours;
