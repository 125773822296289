import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
} from '@material-ui/core';
import mainStyle from "../../../style/default";
import CardTemplate from './CardTemplate';
import clsx from 'clsx';

const Industry = ({ stage, prevStage, skipStage, nextStage, selectedIndustry, selectIndustry }) => {
  const main = mainStyle()();
  const industryList = [
    "Agriculture, Forestry and Fishing",
    "Mining",
    "Manufacturing",
    "Electricity, Gas, Water and Waste Services",
    "Construction",
    "Wholesale Trade",
    "Retail Trade",
    "Accommodation and Food Services",
    "Transport, Postal and Warehousing",
    "Information Media and Telecommunications",
    "Financial and Insurance Services",
    "Rental, Hiring and Real Estate Services",
    "Professional, Scientific and Technical Services",
    "Administrative and Support Services",
    "Public Administration and Safety",
    "Education and Training",
    "Health Care and Social Assistance",
    "Arts and Recreation Services",
    "Other"
  ];

  return (
    <CardTemplate
      stage={stage} 
      minStage={8}
      stageId="#stage8"
      stageAvatar="8" 
      stageTitle="Business Industry"
      subHeader="Question 8 of 9"
      prevStage={prevStage}
      skipStage={() => skipStage(9)}
      nextStage={nextStage}
      nextTitle="Save"
    >
      <Typography variant="body1">What industry does your business operate in?</Typography>
      {industryList && industryList.map((data) => {

        return (
          <Button 
            key={data}
            type="button" 
            color={selectedIndustry == data ? 'primary' : 'default' } 
            variant={selectedIndustry == data ? 'contained' : 'outlined' } 
            className={clsx(main.noRightMargin, main.industryButton)} 
            onClick={() => selectIndustry(data)}
          >{data}</Button>
        );
      })}
    </CardTemplate>
  );
};

Industry.propTypes = {
  stage: PropTypes.number,
  prevStage: PropTypes.func,
  skipStage: PropTypes.func,
  nextStage: PropTypes.func,
  selectedIndustry: PropTypes.any,
  selectIndustry: PropTypes.func
};

Industry.defaultProps = {
};

export default Industry;
