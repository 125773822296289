import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import SimpleTimeSelect from './SimpleTimeSelect';

const OpenCloseTime = ({ record, handleRecord }) => {
  const updateTime = (e, o) => {
    if (o == 'open') {
      if (e.target.value < record.close) {
        handleRecord({
          label: record.label,
          working: record.working,
          open: e.target.value,
          close: record.close,
        });
      }
    }
    if (o == 'close') {
      if (e.target.value > record.open) {
        handleRecord({
          label: record.label,
          working: record.working,
          open: record.open,
          close: e.target.value,
        });
      }
    }
  };

  const handleOpenClose = () => {
    handleRecord({
      label: record.label,
      working: !record.working,
      open: record.open,
      close: record.close,
    });
  };

  return (
    <FormControl style={{ display: 'inherit' }}>
      <Grid container spacing={3} >
        <Grid item xs={4}>
          <FormControlLabel control={<Switch checked={record.working} onChange={handleOpenClose} />} label={record.label} />
        </Grid>
        {record.working ? (
          <>
            <Grid item xs={4}>
              <SimpleTimeSelect label={"Open"} value={record.open} handleChange={(e) => updateTime(e, 'open')} />
            </Grid>
            <Grid item xs={4}>
              <SimpleTimeSelect label={"Close"} value={record.close} handleChange={(e) => updateTime(e, 'close')} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={8}>
              <Typography variant="body1" style={{ margin: '0.4em 1.3em' }}>Closed</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </FormControl>
  );
};

OpenCloseTime.propTypes = {
  record: PropTypes.any,
  handleRecord: PropTypes.func,
};

OpenCloseTime.defaultProps = {
};

export default OpenCloseTime;
