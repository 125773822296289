import React from 'react';
import { 
    Add, 
    AddShoppingCart, 
    Announcement,
    ArrowDownward,
    ArrowUpward,
    Assignment, 
    BarChart, 
    Block, 
    Bookmark, 
    BookmarkBorder, 
    CancelOutlined, 
    ChatBubbleOutlined, 
    ChevronLeft, 
    ChevronRight, 
    CloudOutlined,
    Dashboard, 
    DeleteOutlined, 
    Edit,
    EmailOutlined,  
    ExpandLessOutlined,
    ExpandMoreOutlined,
    FavoriteOutlined, 
    FileCopy, 
    FilterList, 
    FolderOpen, 
    HomeOutlined,
    HighlightOff, 
    InfoOutlined, 
    KeyboardArrowDown,
    Layers, 
    LockOutlined, 
    MailOutline, 
    MailOutlined, 
    MapOutlined, 
    MenuOutlined, 
    Notifications,
    NoteOutlined, 
    NotificationsNone, 
    OpenInNew, 
    Person, 
    People, 
    Phone, 
    QuestionAnswer, 
    RadioButtonUncheckedRounded, 
    Save, 
    Search, 
    Send,
    Share, 
    ShoppingCart, 
    Sms,
    SmsOutlined,
    SpaOutlined, 
    StorefrontOutlined, 
    Today,
    RoomOutlined,
    CloudUploadOutlined,
} from '@material-ui/icons';
import { Grid, Typography } from '@material-ui/core';

export const IconAdd = (props) => { return (<Add {...props} />); };
export const IconAnnouncement = (props) => { return (<Announcement {...props} />); };
export const IconArrowDownward = (props) => { return (<ArrowDownward {...props} />); };
export const IconArrowUpward = (props) => { return (<ArrowUpward {...props} />); };
export const IconAssignment = (props) => { return (<Assignment {...props} />); };
export const IconBarChart = (props) => { return (<BarChart {...props} />); };
export const IconBlock = (props) => { return (<Block {...props} />); };
export const IconBookmarkEmpty = (props) => { return (<BookmarkBorder {...props} />); };
export const IconBookmarkSaved = (props) => { return (<Bookmark {...props} />); };
export const IconCalendar = (props) => { return (<Today {...props} />); };
export const IconCancel = (props) => { return (<CancelOutlined {...props} />); };
export const IconCartAdd = (props) => { return (<AddShoppingCart {...props} />); };
export const IconChat = (props) => { return (<ChatBubbleOutlined {...props} />); };
export const IconChevronLeft = (props) => { return (<ChevronLeft {...props} />); };
export const IconChevronRight = (props) => { return (<ChevronRight {...props} />); };
export const IconDashboard = (props) => { return (<Dashboard {...props} />); };
export const IconDelete = (props) => { return (<DeleteOutlined {...props} />); };
export const IconEdit = (props) => { return (<Edit {...props} />); };
export const IconEmail = (props) => { return (<EmailOutlined {...props} />); };
export const IconExpandLess = (props) => { return (<ExpandLessOutlined {...props} />); };
export const IconExpand = (props) => { return (<ExpandMoreOutlined {...props} />); };
export const IconFavourite = (props) => { return (<FavoriteOutlined {...props} />); };
export const IconFileCopy = (props) => { return (<FileCopy {...props} />); };
export const IconFilter = (props) => { return (<FilterList {...props} />); };
export const IconFolderOpen = (props) => { return (<FolderOpen {...props} />); };
export const IconHome = (props) => { return (<HomeOutlined {...props} />); };
export const IconHighlightOff = (props) => { return (<HighlightOff {...props} />); };
export const IconInfo = (props) => { return (<InfoOutlined {...props} />); };
export const IconKeyboardArrowDown = (props) => { return (<KeyboardArrowDown {...props} />); };
export const IconLayers = (props) => { return (<Layers {...props} />); };
export const IconLock = (props) => { return (<LockOutlined {...props} />); };
export const IconMail = (props) => { return (<MailOutlined {...props} />); };
export const IconMailOutline = (props) => { return (<MailOutline {...props} />); };
export const IconMap = (props) => { return (<MapOutlined {...props} />); };
export const IconMenu = (props) => { return (<MenuOutlined {...props} />); };
export const IconNoteOutlined = (props) => { return (<NoteOutlined {...props} />); };
export const IconNotification = (props) => { return (<Notifications {...props} />); };
export const IconNotificationsNone = (props) => { return (<NotificationsNone {...props} />); };
export const IconOpenInNew = (props) => { return (<OpenInNew {...props} />); };
export const IconPeople = (props) => { return (<People {...props} />); };
export const IconPerson = (props) => { return (<Person {...props} />); };
export const IconPhone = (props) => { return (<Phone {...props} />); };
export const IconQuestionAnswer = (props) => { return (<QuestionAnswer {...props} />); };
export const IconRadioButton = (props) => { return (<RadioButtonUncheckedRounded {...props} />); };
export const IconRoomOutlined = (props) => { return (<RoomOutlined {...props} />); };
export const IconSave = (props) => { return (<Save {...props} />); };
export const IconSearch = (props) => { return (<Search {...props} />); };
export const IconSend = (props) => { return (<Send {...props} />); };
export const IconShare = (props) => { return (<Share {...props} />); };
export const IconShoppingCart = (props) => { return (<ShoppingCart {...props} />); };
export const IconSMS = (props) => { return (<Sms {...props} />); };
export const IconSMSOutlined = (props) => { return (<SmsOutlined {...props} />); };
export const IconSpa = (props) => { return (<SpaOutlined {...props} />); };
export const IconStorefront = (props) => { return (<StorefrontOutlined {...props} />); };
export const IconUpload = (props) => { return (<CloudUploadOutlined {...props} />); };
export const IconWebsite = (props) => { return (<CloudOutlined {...props} />); };

const Icons = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h2">Icons</Typography>
            </Grid>
            <Grid item xs={1}>
                <IconAdd />
            </Grid>
            <Grid item xs={1}>
                <IconAnnouncement />
            </Grid>
            <Grid item xs={1}>
                <IconArrowUpward />
            </Grid>
            <Grid item xs={1}>
                <IconAssignment />
            </Grid>
            <Grid item xs={1}>
                <IconBarChart />
            </Grid>
            <Grid item xs={1}>
                <IconBlock />
            </Grid>
            <Grid item xs={1}>
                <IconBookmarkEmpty />
            </Grid>
            <Grid item xs={1}>
                <IconBookmarkSaved />
            </Grid>
            <Grid item xs={1}>
                <IconCalendar />
            </Grid>
            <Grid item xs={1}>
                <IconCancel />
            </Grid>
            <Grid item xs={1}>
                <IconCartAdd />
            </Grid>
            <Grid item xs={1}>
                <IconChat />
            </Grid>
            <Grid item xs={1}>
                <IconChevronLeft />
            </Grid>
            <Grid item xs={1}>
                <IconChevronRight />
            </Grid>
            <Grid item xs={1}>
                <IconDashboard />
            </Grid>
            <Grid item xs={1}>
                <IconDelete  />
            </Grid>
            <Grid item xs={1}>
                <IconEdit />
            </Grid>
            <Grid item xs={1}>
                <IconEmail />
            </Grid>
            <Grid item xs={1}>
                <IconExpandLess />
            </Grid>
            <Grid item xs={1}>
                <IconExpand />
            </Grid>
            <Grid item xs={1}>
                <IconFavourite />
            </Grid>
            <Grid item xs={1}>
                <IconFileCopy />
            </Grid>
            <Grid item xs={1}>
                <IconFilter />
            </Grid>
            <Grid item xs={1}>
                <IconFolderOpen  />
            </Grid>
            <Grid item xs={1}>
                <IconHighlightOff />
            </Grid>
            <Grid item xs={1}>
                <IconInfo />
            </Grid>
            <Grid item xs={1}>
                <IconLayers />
            </Grid>
            <Grid item xs={1}>
                <IconLock />
            </Grid>
            <Grid item xs={1}>
                <IconMail />
            </Grid>
            <Grid item xs={1}>
                <IconMailOutline />
            </Grid>
            <Grid item xs={1}>
                <IconMap />
            </Grid>
            <Grid item xs={1}>
                <IconMenu />
            </Grid>
            <Grid item xs={1}>
                <IconNoteOutlined />
            </Grid>
            <Grid item xs={1}>
                <IconNotification />
            </Grid>
            <Grid item xs={1}>
                <IconNotificationsNone />
            </Grid>
            <Grid item xs={1}>
                <IconOpenInNew />
            </Grid>
            <Grid item xs={1}>
                <IconPeople />
            </Grid>
            <Grid item xs={1}>
                <IconPerson />
            </Grid>
            <Grid item xs={1}>
                <IconPhone />
            </Grid>
            <Grid item xs={1}>
                <IconQuestionAnswer />
            </Grid>
            <Grid item xs={1}>
                <IconRadioButton />
            </Grid>
            <Grid item xs={1}>
                <IconSave />
            </Grid>
            <Grid item xs={1}>
                <IconSearch />
            </Grid>
            <Grid item xs={1}>
                <IconSend />
            </Grid>
            <Grid item xs={1}>
                <IconShare />
            </Grid>
            <Grid item xs={1}>
                <IconShoppingCart />
            </Grid>
            <Grid item xs={1}>
                <IconSMS />
            </Grid>
            <Grid item xs={1}>
                <IconSMSOutlined />
            </Grid>
            <Grid item xs={1}>
                <IconSpa />
            </Grid>
            <Grid item xs={1}>
                <IconStorefront />
            </Grid>
            <Grid item xs={1}>
                <IconUpload />
            </Grid>
            <Grid item xs={1}>
                <IconWebsite />
            </Grid>
        </Grid>
    );
};

export default Icons;
