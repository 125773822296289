import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import CardTemplate from './CardTemplate';

const Promote = ({ stage, prevStage, skipStage, nextStage, values }) => {
  return (
    <CardTemplate
      stage={stage} 
      minStage={3}
      stageId="#stage3"
      stageAvatar="3" 
      stageTitle="Business Listing"
      subHeader="Question 3 of 9"
      prevStage={prevStage}
      skipStage={() => skipStage(9)}
      skipTitle="Not Yet"
      nextStage={nextStage}
      nextTitle="Yes"
    >
      <Typography variant="body1">Hi {values.first + ' ' + values.last}, do you have a business you would like to promote for free?</Typography>
    </CardTemplate>
  );
};

Promote.propTypes = {
  stage: PropTypes.number,
  prevStage: PropTypes.func,
  skipStage: PropTypes.func,
  nextStage: PropTypes.func,
  values: PropTypes.any,
};

Promote.defaultProps = {
};

export default Promote;
