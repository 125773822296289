import React, { useState } from 'react';
import { Switch } from 'react-router-dom';
import { Router, Route } from 'react-router';
import './scss/style.scss';
import history from './api/history';
import LoadingOverlay from 'react-loading-overlay';
import { PuffLoader } from 'react-spinners';
import Preview from './views/layout/Preview';
import RegisterQuestions from './views/pages/register/RegisterQuestions';

const loading = (
  <PuffLoader
    size={60}
    color={"#ffffff"}
  />
);

// Containers
const TheLayout = React.lazy(() => import('./views/layout/TheLayout'));
const Account = React.lazy(() => import('./views/layout/Account'));
const Public = React.lazy(() => import('./views/layout/Public'));
const Search = React.lazy(() => import('./views/layout/Search'));
const WhatsNew = React.lazy(() => import('./views/layout/WhatsNew'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Validate = React.lazy(() => import('./views/pages/register/Validate'));
const Verify = React.lazy(() => import('./views/pages/register/Verify'));
const ResetPassword = React.lazy(() => import('./views/pages/reset/password'));
const ResetToken = React.lazy(() => import('./views/pages/reset/reset'));
const Directory = React.lazy(() => import('./views/pages/directory/Directory'));
const Create = React.lazy(() => import('./views/pages/directory/Create'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function App() {
  const [isLoading, setIsLoading] = useState(0);

  const setLoading = (val) => {
    if (isLoading > 0) {
      setIsLoading(isLoading + (val ? 1 : -1));
    } else if (val && isLoading == 0) {
      setIsLoading(val ? 1 : -1);
    } else if (!val) {
      setIsLoading(0);
    }
  };

  return (
    <Router history={history}>
        <LoadingOverlay
          active={isLoading > 0}
          spinner={loading}
        >
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/404" render={() => <Page404 />} />
              <Route exact path="/500" render={() => <Page500 />} />

              <Route exact path="/register" render={() => <Register />} /> {/* todo: fix page */}
              <Route exact path="/register-questions" render={props => <RegisterQuestions setIsLoading={(val) => setLoading(val)} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/validate" render={() => <Validate />} /> {/* todo: fix page */}
              <Route exact path="/verify/:token" render={props => <Verify {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/login" render={props => <Login setIsLoading={(val) => setLoading(val)} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/logout" render={props => <Login setIsLoading={(val) => setLoading(val)} logout {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/reset" render={() => <ResetPassword />} /> {/* todo: fix page */}
              <Route exact path="/reset/:token" render={props => <ResetToken {...props}/>} /> {/* todo: fix page */}

              <Route exact path="/search" render={props => <Search setIsLoading={(val) => setLoading(val)} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/search/product/:hash/:longHash" render={props => <Search setIsLoading={(val) => setLoading(val)} showProduct={true} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/search/view/:companyname?/:searchhash?" render={props => <Search setIsLoading={(val) => setLoading(val)} showDirectoryView={true} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/cart" render={props => <Search setIsLoading={(val) => setLoading(val)} showCart={true} {...props}/>} /> {/* todo: fix page */}

              <Route exact path="/whats-new" render={props => <WhatsNew {...props}/> } /> {/* todo: fix page */}

              <Route exact path="/directory" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} showDirectory={true} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/directory/edit" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} editDirectory={true} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/directory/view/:companyname?/:searchhash?" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} showDirectoryView={true} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/directory/preview/:companyname?" render={props => <Directory {...props}/>} />   {/* todo: fix page */}

              <Route exact path="/dashboard" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} showDashboard={true} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/profile" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} showProfile={true} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/profile/upgrade" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} showProfile={true} showUpgrade={true} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/teams" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} showTeams={true} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/list" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/product" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} showProduct={true} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/account/:section/:hash?" render={props => <Account setIsLoading={(val) => setLoading(val)} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/create/:token" render={() => <Create />} />  {/* todo: fix page */}

              <Route exact path="/request/view/:hash" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} showRequestView={true} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/request/create" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} showRequestCreate={true} {...props}/>} /> {/* todo: fix page */}
              
              <Route exact path="/services" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/services/create" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} {...props}/>} /> {/* todo: fix page */}
              
              <Route exact path="/tasks" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} {...props}/>} /> {/* todo: fix page */}
              <Route exact path="/tasks/create" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} {...props}/>} /> {/* todo: fix page */}

              <Route exact path="/message" render={props => <Preview  {...props}/> } /> {/* todo: create message page */}
              <Route exact path="/message/view" render={props => <Preview  {...props}/> } /> {/* todo: create message view page */}
              <Route exact path="/notification" render={props => <Preview  {...props}/> } /> {/* todo: create notification page */}
              <Route exact path="/chat" render={props => <TheLayout setIsLoading={(val) => setLoading(val)} showChat={true} {...props}/>} /> {/* todo: fix page */}

              <Route exact path="/advertise" render={props => <Preview  {...props}/> } /> {/* todo: fix page */}

              <Route exact path="/coming-soon" render={props => <TheLayout showComingSoon={true} {...props}/> } /> {/* todo: fix page */}

              <Route exact path="/preview" render={props => <Preview  {...props}/> } /> {/* todo: fix page */}
              
              <Route path="/" render={props => <Public {...props}/>} /> {/* todo: fix page */}
            </Switch>
          </React.Suspense>
        </LoadingOverlay>
    </Router>
  );
}

export default App;
