import { createStore } from 'redux';

const initialState = {
  sidebarShow: 'responsive',
  dataPanelShow: false, // 'responsive'
  buttonList: [],
  buttonActive: [],
  section: 'dashboard',
  view: 'view',
  action: 'read',
  formValues: [],
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;