import React from "react";
import { IconBookmarkEmpty, IconBookmarkSaved } from "./views/component/Icons";

export const loginUser = (token, store, apiClient, history) => {
    const appState = {
      isLoggedIn: true,
      token,
    };
    localStorage.setItem('appState', JSON.stringify(appState));
    store.dispatch({
        type: 'SET_AUTH',
        payload: appState,
    });

    apiClient.get('/user',{
      headers: { Authorization: `Bearer ${token}` }
    }).then(() => {
      history.push('/dashboard'); 
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      history.push('/login'); 
    });
};

export const logoutUser = (store, apiClient, history, path = '/login#logout') => {
  apiClient.post('/logout').then(() => { 
    const appState = {
      isLoggedIn: true,
      token: null,
    };
    localStorage.setItem('appState', JSON.stringify(appState));
    store.dispatch({
        type: 'SET_AUTH',
        payload: appState,
    });

    history.push(path); 
  });
};

export const upperCase = (word) => word.toUpperCase().substr(0,1) + word.substr(1);

export const bookmarkIcon = (isBookmarked) => isBookmarked ? (<IconBookmarkSaved />) : (<IconBookmarkEmpty />);
