import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from '@material-ui/core';
import mainStyle from "../../../style/default";

const Welcome = ({ nextStage, totalStages }) => {
  const main = mainStyle()();

  return (
    <Card className={main.defaultCard}>
      <CardContent>
        <Typography variant="body1">Hi, we have {totalStages} questions we would like to ask that will help in setting up your account. Some can be skipped and completed later.</Typography>
      </CardContent>
      <CardActions>
        <Button type="button" color="primary" variant="contained" className={main.noLeftMargin} onClick={nextStage}>Start</Button>
      </CardActions>
    </Card>                
  );
};

Welcome.propTypes = {
  nextStage: PropTypes.func,
  totalStages: PropTypes.number,
};

Welcome.defaultProps = {
};

export default Welcome;
