import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import mainStyle from "../../../style/default";
import PasswordForm from 'src/views/component/profile/PasswordForm';
import CardTemplate from './CardTemplate';

const PasswordCard = ({ stage, prevStage, nextStage, values, errors, touched, handleChange, handleBlur, isValid, validateForm }) => {
  const main = mainStyle()();

  return (
    <CardTemplate
      stage={stage} 
      minStage={9}
      stageId="#stage9"
      stageAvatar="9" 
      stageTitle="Password"
      subHeader="Question 9 of 9"
      prevStage={prevStage}
      nextStage={() => {
        validateForm();
        if (isValid) {
          nextStage();
        }
      }}
      nextTitle="Save"
    >
      <Typography variant="body1">Please enter a password for your account</Typography>
      <Grid container>
        <Grid item xs={12} className={main.defaultPadding}>
          <PasswordForm showResetButton={false} values={values} errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} />
        </Grid>
      </Grid>
    </CardTemplate>
  );
};

PasswordCard.propTypes = {
  stage: PropTypes.number,
  prevStage: PropTypes.func,
  nextStage: PropTypes.func,
  values: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  isValid: PropTypes.any,
  validateForm: PropTypes.func,
};

PasswordCard.defaultProps = {
};

export default PasswordCard;
