import axios from 'axios';
import store from '../store';
// import history from './history';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    // mode: "no-cors",
    // credentials: 'include',
    headers: {
      'Accept': "application/json",
      'Content-Type': "application/json",
    },
});

apiClient.interceptors.request.use((config) => {
    const appState = localStorage.getItem('appState');
    if (appState) {
      const token = JSON.parse(appState).token ?? null;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }  
    }
    return config;
});

apiClient.interceptors.response.use(async (response) => response, async (error) => {
  console.log({error});

  if (error.response) {
    // Request made and server responded
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } /* else if (error.request) {
     // The request was made but no response was received
     console.log(error.request);
  } else {
     // Something happened in setting up the request that triggered an Error
     console.log('Error', error.message);
  } */
  if (error?.response?.status !== 200) {
      const appState = {
          isLoggedIn: false,
          token: null,
        };
        localStorage.setItem('appState', JSON.stringify(appState));
        store.dispatch({
            type: 'SET_AUTH',
            payload: appState,
        });
        /*
        todo: define redirection urls for errors
        if (error?.response?.status !== 401 && error?.response?.status !== 422) {
          history.push(error?.config?.url ?? '/login');
        }
        */
  }
  return error?.response;
});

export default apiClient;
