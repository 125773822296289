import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import CardTemplate from './CardTemplate';

const Thankyou = ({ stage, prevStage, values }) => {
  return (
    <CardTemplate
      stage={stage} 
      minStage={10}
      stageId="#stage10"
      stageAvatar=" " 
      stageTitle="Thank you"
      subHeader=""
      prevStage={prevStage}
      submitTitle="Login"
    >
      <Typography variant="body1">Thank you {values.first + ' ' + values.last} for the information you have provided. Please click on Login to create your account and login to the platform.</Typography>
    </CardTemplate>      
  );
};

Thankyou.propTypes = {
  stage: PropTypes.number,
  prevStage: PropTypes.func,
  values: PropTypes.any,
};

Thankyou.defaultProps = {
};

export default Thankyou;
