import React from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, Container, Grid } from '@material-ui/core';
import mainStyle from '../style/default';

const Preview = ({ setIsLoading }) => {
  const main = mainStyle()();

  console.log({ setIsLoading });
  
  return (
    <div className={main.root}>
      <CssBaseline />
      <main className={main.content}>
        <Container maxWidth="lg" className={main.containerPublic}>
          <Grid item xs={12}>
            
          </Grid>
        </Container>
      </main>
    </div>
  );
};

Preview.propTypes = {
  setIsLoading: PropTypes.func,
};

Preview.defaultProps = {
};

export default Preview;
