import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, Grid, Typography } from '@material-ui/core';
import Field from './Field';
import mainStyle from 'src/views/style/default';
import clsx from 'clsx';

const PasswordForm = ({ showResetButton, values, errors, touched, handleChange, handleBlur }) => {
  const main = mainStyle()();

  const layoutDetails = [
    {
      name: 'password',
      gridSize: {'xs': 12, 'md': 12},
      type: 'password',
    },
    {
      name: 'confirmation',
      gridSize: {'xs': 12, 'md': 12},
      type: 'password',
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} className={main.defaultPadding}>
        {layoutDetails && layoutDetails.map((item, key) => {
            return (
              <FormControl key={key} style={{ display: 'inherit' }}>
                <Field fieldType={item.type} fieldName={item.name} gridSize={item.gridSize} values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} />
              </FormControl>
              );
        })}
        <Typography variant="caption" className={clsx(main.defaultPadding, main.passwordRequirements)}>
          Requirements for your password:
          <ul>
            <li>Minimum of 8 characters</li>
            <li>One lowercase character</li>
            <li>One uppercase character</li>
            <li>One special character (@,$,!,%,*,#,?,&amp;)</li>
            <li>One number</li>
          </ul>
        </Typography>
      </Grid>
      {showResetButton && (
        <Grid item xs={12} className={main.defaultPadding}>
          <Button type="submit" color="secondary" variant="outlined">Reset Password</Button>
        </Grid>
      )}
    </Grid>
  );
};

PasswordForm.propTypes = {
  showResetButton: PropTypes.bool,
  values: PropTypes.any, 
  errors: PropTypes.any, 
  touched: PropTypes.any, 
  handleChange: PropTypes.func, 
  handleBlur: PropTypes.func
};

PasswordForm.defaultProps = {
  showResetButton: true,
};

export default PasswordForm;
