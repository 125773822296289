import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;
const startColor = (props, theme) => {
  if (props?.pos) {
    return props.pos == 0 ? theme.palette.primary.main : props.pos > 2 ? '#546e7a' : theme.palette.secondary.main;
  }
  return theme.palette.primary.main;
};
const endColor = (props, theme) => {
  if (props?.pos) {
    return props.pos == 0 ? theme.palette.primary.dark : props.pos > 2 ? '#37474f' : theme.palette.secondary.dark;
  }
  return theme.palette.primary.dark;
};

const displayFlex = {
  display: 'flex',
};

const alignItemsCenter = {
  alignItems: 'center',
};

const flexGrowOne = {
  flexGrow: 1,
};

export default props => makeStyles((theme) => ({
  root: {
    ...displayFlex,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...displayFlex,
    ...alignItemsCenter,
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    ...flexGrowOne,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    ...flexGrowOne,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    maxWidth: '100vw',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  fixedHeight: {
    height: 240,
  },
  iconButton: {
    borderRadius: '0',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'initial',
      borderRadius: '0',
      boxShadow: 'none',
      color: 'inherit',
      transform: 'initial',
    }
  },
  defaultRadius: {
    borderRadius: '4px',
    '&:hover': {
      borderRadius: '4px',
    }
  },
  squareButton: {
    borderRadius: '0',
    '&:hover': {
      borderRadius: '0',
    }
  },
  calendar: {
    borderWidth: `0 !important`,
    "& .react-calendar__viewContainer": {
      "& .react-calendar__tile--active": {
        backgroundColor: `#01a8b8 !important`,
        color: 'white !important',
      },
      "& .react-calendar__tile--now": {
        backgroundColor: `rgba(0,0,0,0) !important`,
        color: `#01a8b8 !important`,
      },
      "& .react-calendar__tile--active.react-calendar__tile--now": {
        backgroundColor: `#01a8b8 !important`,
        color: 'white !important',
      }
    },
    "& .react-calendar__navigation": {
      backgroundColor: "#01a8b8",
      "& .react-calendar__navigation__arrow": {
        "&:enabled": {
          "&:hover, &:focus": {
            backgroundColor: `#007580 !important`
          }
        }
      },
      "& .react-calendar__navigation__label": {
        backgroundColor: "#01a8b8",
        boxShadow: "initial",
        fontWeight: '400',
        "&:enabled": {
          "&:hover, &:focus": {
          }
        }
      }
    },
    "& button.react-calendar__navigation__arrow": {
      backgroundColor: "#01a8b8",
      boxShadow: "initial",
      "&:hover, &:focus": {
        backgroundColor: `initial !important`
      }
    },
    "& .react-calendar__month-view__weekdays": {
      padding: '1em 0em',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    "& abbr[title]": {
      textDecoration: 'none !important',
    }
  },
  centerAlign: {
    textAlign: 'center',
  },
  avatar: {
    border: 'solid 2px #ebedef', 
    borderRadius: '50%', 
    maxHeight: '36px', 
    maxWidth: '36px',
    padding: '2px', 
  },
  menu: {
    color: 'inherit',
    fontSize: '1em',
    '& li': {
      margin: '8px',
      padding: '8px',
    }
  }, 
  menuBadge: {
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      transform: 'scale(1) translate(110%, 10%) !important'
    }
  },
  image: {
    maxHeight: theme.spacing(4),
    maxWidth: theme.spacing(4),
  },
  delete: {
    border: 'none',
    boxShadow: 'none',
    color: 'inherit',
    height: 'initial',
    margin: '0',
    minWidth: 'initial',
    padding: '0',
    verticalAlign: 'initial',
  },
  icon: {
    display: 'inline-block',
    width: '2em', 
  },
  message: {
    display: 'inline-block', 
    lineHeight: '2em', 
    verticalAlign: 'bottom',
    width: 'fit-content', 
  },
  cancel: {
    color: props?.cancelColor ?? theme.palette.primary.main,
    cursor: 'pointer', 
    display: 'inline-block', 
    float: 'right', 
    fontSize: '1rem', 
    lineHeight: '1em', 
    margin: '5px 20px', 
    verticalAlign: 'bottom', 
  },
  url: {
    color: props?.color ?? theme.palette.primary.main,
    display: 'inline-block', 
    float: 'right', 
    fontSize: '1rem', 
    lineHeight: '2em', 
    margin: '2px 20px', 
    verticalAlign: 'bottom', 
  },
  deposit: {
    color: theme.palette.secondary.light,
    flex: 1,
  },
  link: {
    color: theme.palette.secondary.dark,
    float: 'right',
    marginTop: theme.spacing(4),
  },
  actionButton: {
    margin: theme.spacing(3),
    width: '10vw',
  },
  actionTitle: {
    display: 'block',
    margin: theme.spacing(3),
    width: '100%',
  },
  input: {
    paddingRight: '10px',
    '& input': {
      margin: 0,
      maxWidth: 'initial',
    }
  },
  searchButton: {
    borderRadius: '0',
    margin: 0,
    '&:hover': {
      color: '#000000',
    }
  },
  avatarOuter: {
    border: '2px solid #f5f5f5',
    borderRadius: '50%',
  },
  card: {
    marginBottom: '1em',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  checkbox: {
    color: props?.numSelected > 0 ? theme.palette.secondary.main : 'inherit'
  },
  actionsCell: {
    color: 'inherit',
  },
  actionsButton: {
    marginRight: '1em',
  },
  actionsIcon: {
    margin: '0.5em'
  },
  control: {
    marginLeft: '1em'
  },
  initialColor: {
    color: 'inherit',
  },
  formLabel: {
    lineHeight: '2em',
    minWidth: '4em', 
    width: '4em', 
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          backgroundColor: theme.palette.info.light,
          color: theme.palette.info.contrastText,
        }
      : {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.text.primary,
        },
  deleteMargin: {
    margin: '1em'
  },
  small: {
    display: 'block', 
    fontSize: 'small'
  },
  defaultMargin: {
      margin: '1em',
  },
  defaultPadding: {
      padding: '1em',
  },
  noPadding: {
    padding: '0 !important',
  },
  fullWidth: {
      width: '100%',
  },
  error: {
    color: theme.palette.error.dark,
  },
  inputListContainer: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: '40vh',
    overflow: 'auto',
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  listIcon: {
    paddingLeft: '8px',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabButton: {
    '&:hover': {
      color: 'white !important',
    } 
  },
  rightSide: {
    backgroundColor: '#f3f1ed',
    color: '#4b3321',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(16),
  },
  productTitle: {
    clear: 'both', 
    color: '#6c6c6c', 
    float: 'left', 
    letterSpacing: '5px', 
    textTransform: 'uppercase', 
    transform: 'scaleY(0.9)',
    width: '100%', 
  },
  productDescription: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  productImage: {
    padding: theme.spacing(4),
    width: '100%',
  },
  productAction: {
    minHeight: '30vh',
    '& .alignLeft': {
      clear: 'both', 
      float: 'left',
    }
  },
  productRating: {
    clear: 'both', 
    color: `${props?.rating === props?.avgRating ? '#ffb400' : ( props?.rating < props?.avgRating ? '#d81b60' : '#7cb342') }`,
    float: 'left', 
    verticalAlign: 'sub', 
  },
  bookmarkButton: {
    backgroundColor: '#ad1457',
  },
  cartButton: {
    backgroundColor: theme.palette.primary.dark,
  },
  cartDeleteButton: {
    backgroundColor: '#ad1457',
  },
  rightAlign: {
    float: 'right',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  header: {
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(4),
  },
  search: {
    marginTop: theme.spacing(4),
    width: '50vw',
    '& .MuiOutlinedInput-root': {
      borderRadius: '30px',
      paddingLeft: theme.spacing(4),
      backgroundColor: '#ffffff',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.light, // '#bdbdbd'
    }
  },
  action: {
    margin: theme.spacing(2),
  },
  containerView: {
    backgroundColor: 'white',
    position: 'sticky',
    top: '0',
  },
  searchBar: {
    // borderBottom: 'solid 1px #ccc',
    float: 'left',
    width: '100%',
    padding: theme.spacing(2),
    minHeight: '81px',
  },
  cta: {
    background: theme.palette.secondary.dark,
    color: theme.palette.grey[100], // '#D6D2C4',
    display: 'block',
    fontSize: '1em',
    fontWeight: 200,
    letterSpacing: '3px',
    textAlign: 'center',
  },
  company: {
    color: '#f5f5f5',
    fontSize: '1em',
    fontWeight: 600,
    letterSpacing: '2px',
    marginTop: '5px',
  },
  background: {
    background: 'linear-gradient(150deg, rgba(255,245,234,1) 0%, rgba(247,218,217,1) 100%)'
  },
  default: {
    color: '#ffffff',
  },
  ctaDefault: {
    background: theme.palette.primary.dark, 
    color: '#ffffff',
  },
  shareButton: {
    height: `${props?.iconSize ?? 32}px`,
    marginLeft: theme.spacing(1),
    marginTop: 0,
  },
  table: {
    minWidth: 750,
    padding: '1em',
  },
  box: {
    width: '100%', height: '100%'
  },
  avatarIconButton: {
    maxWidth: '40px',
    marginRight: '0.5em',
    borderRadius: '0',
    boxShadow: 'none !important',
    backgroundColor: 'initial !important',
    transform: 'none !important',
    '&:hover': {
      backgroundColor: 'none !important',
    }
  },
  paper: {
    flexDirection: 'column',
    overflow: 'auto',
    padding: theme.spacing(2),
    ...displayFlex,
  },
  paperCart: {
    borderBottom: 'solid 1px #f5f5f5',
    borderRadius: '0',
    boxShadow: 'none',
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '40vw',
  },
  paperDeposit: {
    borderBottom: 'solid 1px #f5f5f5',
    borderRadius: '0',
    boxShadow: 'none',
    color: theme.palette.text.secondary,
    minHeight: '200px',
    padding: theme.spacing(2),
  },
  cartMenuIconButton: {
    // backgroundColor: props?.iconClicked ? props?.primaryColor || theme.palette.primary.main : theme.palette.secondary.main,
    marginRight: '0.5em',
    maxWidth: '40px', 
    borderRadius: '0',
    boxShadow: 'none !important',
    backgroundColor: 'initial',
    transform: 'none !important',
    '&:hover': {
      backgroundColor: props?.iconClicked ? props?.primaryColor || theme.palette.primary.main : theme.palette.secondary.main,
    }
  },
  dashboardContainer: {
    backgroundColor: props?.backgroundColor ?? '#ffffff', 
    borderRadius: '0.4em',
    color: props?.color ?? theme.palette.primary.main, 
    padding: '1em', 
  },
  paperForm: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  containerDialogFilter: {
    maxHeight: 440,
  },
  avatarDirectoryResult: {
    border: '2px solid #616161',
  },
  cardDirectoryResult: {
    marginBottom: '1em',
  },
  iconButtonDirectoryResult: {
    border: 'solid 1px white',
    boxShadow: 'none',
    marginLeft: '1em',
    '&:hover': {
      color: theme.palette.secondary.dark, // '#9e9e9e',
      border: `solid 1px ${theme.palette.secondary.dark}`,
    }
  },
  checkboxTableRow: {
    color: props?.isItemSelected ? 'white' : 'inherit'
  },
  rootTableToolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  titleTableToolbar: {
    flex: '1 1 100%',
  },
  deleteTableToolbar: {
    color: props?.numSelected > 0 ? 'white' : 'inherit'
  },
  containerFooter: {
    backgroundColor: '#616161', 
    color: 'white',  
    margin: '0', 
    maxWidth: '100vw', 
    padding: '2em', 
    position: 'sticky'
  },
  rootInput: {
    ...flexGrowOne,
    maxWidth: 752,
  },
  paperMessageBodySmall: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
  },
  avatarMessageBodySmall: {
    '& .avatar': {
      border: 'solid 2px #ebedef',
      borderRadius: '50%', 
      padding: '2px', 
    },
    '& .name': {
      display: 'inherit'
    },
  },
  messageMessageBodySmall: {
    textAlign: 'left',
    '& .title': {
      fontWeight: 'bold'
    },
    '& .abstract': {
      whiteSpace: 'normal'
    }
  },
  paperMessageMenu: {
    borderBottom: 'solid 1px #f5f5f5',
    borderRadius: '0',
    boxShadow: 'none',
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
  },
  iconButtonMessageMenu: {
    backgroundColor: props?.iconClicked ? theme.palette.primary.main : theme.palette.secondary.main,
    marginRight: '0.5em',
    maxWidth: '40px', 
    '&:hover': {
      backgroundColor: props?.iconClicked ? theme.palette.primary.main : theme.palette.secondary.main,
    }
  },
  paperNotification: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '40vw',
  },
  avatarNotification: {
    '& .avatar': {
      borderRadius: '50%', 
      padding: '2px', 
      border: 'solid 2px #ebedef'
    },
    '& .name': {
      display: 'inherit'
    },
  },
  messageNotification: {
    textAlign: 'left',
    '& .title': {
      fontWeight: 'bold'
    },
    '& .abstract': {
      whiteSpace: 'normal'
    }
  },
  paperPageItem: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',
  },
  containerPublic: {
    maxWidth: 1000,
    padding: '0',
    textAlign: 'center',
  },
  actionButtonProductCard: {
    backgroundColor: '#B3AB66', /* '#bd9845', */
    color: 'white',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    width: '100%',
  },
  inputSearch: {
    boxShadow: 'none',
    maxWidth: '100%',
    '& :focus': {
      boxShadow: 'none !important',
    },
  },
  cursorPointer: {
      cursor: 'pointer',
  },
  containerSearch: {
    margin: '0',
    maxWidth: '100vw',
    padding: '0',
  },
  headerSearch: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    fontSize: '2em',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  searchArea: {
    marginTop: theme.spacing(1),
    width: '30vw',
    maxWidth: '350px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '30px',
      paddingLeft: theme.spacing(2),
      backgroundColor: '#ffffff',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e8e8e8',
      boxShadow: '2px 2px #d6d6d652',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.light,
    }
  },
  actionSearch: {
    margin: '0',
    marginLeft: theme.spacing(1),
    maxHeight: '32px',
    verticalAlign: 'sub',
  },
  inputSearchField: {
    boxShadow: 'none',
    margin: '0',
    padding: theme.spacing(1),
    '& :focus': {
      boxShadow: 'none !important',
    }
  },
  rootServiceCard: {
    background: `linear-gradient(150deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
    borderRadius: 0,
    color: theme.palette.primary.light,
    margin: 'auto',
    maxWidth: 304,
    minHeight: '16em',
    position: 'relative',
  },
  contentServiceCard: {
    padding: 24,
  },
  titleServiceCard: {
    color: '#f5f5f5',
    fontSize: '1em',
    letterSpacing: '2px',
  },
  rootTable: {
    width: '100%',
  },
  paperTable: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  containerTable: {
    maxHeight: props?.tableHeight ?? '100vh',
  },
  rootToolbar: {
    backgroundColor: 'inherit',
    borderBottom: 'solid 1px #e0e0e0',
    lineHeight: '55px',
    minHeight: '57px',
    width: '100vw',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  iconButtonToolbar: {
    backgroundColor: 'inherit',
    borderRadius: '0px',
    cursor: 'pointer',
    margin: '0px 4px',
    padding: '6px 12px',
  },
  buttonAppointment: {
    float: 'left',
    margin: '1em', 
    minWidth: '120px', 
    width: '120px', 
  },
  labelChart: {
    fill: theme.palette.text.primary,
    textAnchor: 'middle', 
  },
  componentDefault: {
    margin: '0.1rem',
    padding: '0.3rem', 
  },
  cardActionColor: {
    color: '#616161',
  },
  alignLeft: {
    float: 'left',
  },
  marginRight: {
    marginRight: '0.5em'
  },
  paddingOne: {
    padding: '1em',
  },
  advert: {
    color: theme.palette.secondary.main,
    minHeight: '3em',
    '& .advertBody': {
      paddingLeft: '1em',
      textAlign: 'left',
    },
    '& .about': {
      padding: '1em 0em',
      color: '#424242',
    },
    '& .contact': {
      padding: '1em',
      color: '#757575',
      textAlign: 'left',
    },
    '& .view': {
      float: 'right',
      position: 'absolute', 
      bottom: '0', 
      right: '0', 
      boxShadow: 'none', 
      borderRadius: '0', 
      color: theme.palette.primary.main,
    },
    '& .advertImg': {
      background: `linear-gradient(150deg, ${startColor(props, theme)} 0%, ${endColor(props, theme)} 100%)`,
    }
  },
  whatsNew: {
    '& .info': {
      paddingLeft: '1em', 
      textAlign: 'left',
    },
    '& .abstract': {
      padding: theme.spacing(1),
      paddingLeft: '0',
    },
    '& .more': {
      float: 'right',
      borderRadius: '0px',
    },
  },
  rootWhatsNew: {
    ...flexGrowOne,
    overflow: 'hidden',
    padding: theme.spacing(3, 3),
    margin: theme.spacing(2),
    '& .searchButton': {
      border: 'none',
      boxShadow: 'none',
    }
  },
  paperWhatsNew: {
    maxWidth: 1000,
    margin: `${theme.spacing(2)}px auto`,
  },
  errorPage: {
    backgroundColor: '#f7fafc',
    color: '#a0aec0',
    fontSize: '1.125rem',
  },
  errorContent: {
    'justify-content': 'center',
    'min-height': '100vh',
    'align-items': 'center',
    'vertical-align': 'middle',
    'display': 'flex',
  },
  advertiseButton: {
    border: 'none',
    'box-shadow': 'none',
    transform: 'none',
    background: 'inherit',
    marginTop: '5em',
    marginBottom: '5em',
    padding: '1em',
  },
  textLeft: {
    textAlign: 'left',
  },
  footer: {
    maxWidth: 1000,
    margin: `${theme.spacing(1)}px auto`,
    marginTop: '4em',
  },
  paperHeader: {
    background: theme.palette.primary.dark,
    color: 'white',
    fontSize: '1em',
    padding: '1em',
    borderRadius: '0',
  },
  footerBody1: {
    backgroundColor: '#616161e3',
    color: '#f5f5f5',
  },
  footerMenuItem: {
    '& .MuiListItemIcon-root': {
      minWidth: '32px',
      color: 'white',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    }
  },
  marginTopBottom: {
    margin: `${theme.spacing(2)}px auto`,
  },
  multiline: {
    minHeight: '5em',
  },
  publicMenu: {
    '& .signInButton': {
      borderRadius: '0',
      margin: theme.spacing(1),
      marginRight: '0',
    }
  },
  artist: {
    '& .artistImage': {
      maxWidth: '80vw', 
      maxHeight: '60vh', 
      marginTop: '2em',
    },
    '& .artistLink': {
      width: '100%',
      clear: 'both',
      display: 'block',
      cursor: 'pointer',
    }
  },
  dashboardLink: {
    marginRight: theme.spacing(2),
    color: '#757575',
  },
  borderTop: {
    borderTop: 'solid 4px #757575'
  },
  inputBackground: {
    background: 'white',
  },
  applied: {
    padding: '12px',
    color: '#424242',
  },
  doubleWidth: {
    width: '246px',
  },
  avatarWrapper: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(2),
  },
  basicCard: {
    maxWidth: 800,
    margin: '0',
    padding: '0 !important',
  },
  cardContent: {
    margin: `${theme.spacing(1)}px`,
    padding: theme.spacing(1),
  },
  basicActions: {
    display: 'inherit',
  },
  fadeIn: {
    opacity: '1 !important',
    'animation-name': 'fadeInOpacity',
    'animation-iteration-count': '1',
    'animation-timing-function': 'ease-in',
    'animation-duration': '1s',
  },
  fadeOut: {
    opacity: '0'
  },
  chatMessage: {
    maxWidth: '60vw',
  },
  chatLeft: {
    float: 'left',
    '& .MuiPaper-root': {
      backgroundColor: `${theme.palette.primary.light} !important`,
      border: `solid 1px ${theme.palette.primary.light}`,
      color: `#eeeeee !important`,
    }
  },
  chatRight: {
    float: 'right',
    textAlign: 'right',
    '& .MuiPaper-root': {
      backgroundColor: `${theme.palette.secondary.light} !important`,
      border: `solid 1px ${theme.palette.secondary.light}`,
      color: `#eeeeee !important`,
    }
  },
  defaultMarginTop: {
    marginTop: '1em',
  },
  passwordRequirements: {
    fontSize: '10px',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  backgroundGradient: {
    'background': `linear-gradient(60deg, ${props?.start} 0%, ${props?.end} 100%)`,
    '-webkit-transition': 'background-color 1000ms linear-gradient',
    '-ms-transition': 'background-color 1000ms linear-gradient',
    'transition': 'background-color 1000ms linear-gradient',
    minHeight: '90vh',
    overflow: 'hidden',
  },
  noLeftMargin: {
    marginLeft: 'auto',
  },
  noRightMargin: {
    marginRight: 'auto',
  },
  defaultCard: {
    padding: '2em', 
    fontSize: '1em', 
    width: '40vw', 
    maxWidth: '40vw', 
    margin: '4em auto'
  },
  industryButton: {
    width: '100%', 
    margin: '1em', 
    overflowWrap: 'break-word',
    whiteSpace: 'normal', 
    padding: '1em', 
    height: 'initial'
  },
  revealCard: {
    opacity: `${props?.showCard ? '1' : '0'}`, 
    'transition': 'opacity 1000ms linear' 
  },
  cardAvatar: {
    backgroundColor: `${theme.palette.primary.light}`,
    border: `6px solid ${theme.palette.primary.light}`,
    padding: theme.spacing(1),
    textAlign: 'center',
    display: 'inline-flex',
    '& img': {
      borderRadius: '50% !important',
    }
  },
  upgradeBackground: {
    position: 'relative',
  },
  upgradeForeground: {
    filter: 'blur(8px)',
    '-webkit-filter': 'blur(8px)',
  },
  upgradeText: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    border: '#ccc',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    background: '#eeeeeea6',
    ...displayFlex,
    ...alignItemsCenter,
  }
}));
