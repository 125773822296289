import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Input, InputAdornment } from '@material-ui/core';
import lodash from 'lodash';
import mainStyle from 'src/views/style/default';

const getDisplayName = (value) => {
    return lodash.startCase(value);
};

const getStartIcon = (icon) => {
    if (icon) {
        return (
            <InputAdornment position="start">
                {icon}
            </InputAdornment>
        );
    }
    return null;
};

const Field = ({ fieldType, fieldName, fieldAction, gridSize, startIcon, values, handleChange, handleBlur, errors, touched }) => {
    const main = mainStyle()();

    const showMap = () => (fieldType == 'map');
    const showButton = () => (fieldType == 'button');

    const showText = () => (!showMap() && !showButton());

    return (
        <Grid item 
            xs={gridSize['xs'] ?? 12} 
            md={gridSize['md'] ?? 12} 
            lg={gridSize['lg'] ?? 12} 
            className={main.defaultPadding}
        >
            {showMap() && (
                <Button type="button" color="secondary" variant="outlined" onClick={fieldAction}>{fieldName}</Button>
            )}
            {showButton() && (
                <Button type="button" color="secondary" variant="outlined" onClick={fieldAction}>{fieldName}</Button>
            )}
            {showText() && (
                <>
                    <Input type={fieldType} placeholder={getDisplayName(fieldName)}
                    name={fieldName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[fieldName] ?? ''}
                    className={main.fullWidth}
                    startAdornment={getStartIcon(startIcon)} />
                    <div className={main.error}>
                        {errors[fieldName] && touched[fieldName] && errors[fieldName]}
                    </div>
                </>
            )}
        </Grid>
    );
  };
  
  Field.propTypes = {
    fieldType: PropTypes.string,
    fieldName: PropTypes.string,
    fieldAction: PropTypes.func,
    gridSize: PropTypes.any,
    startIcon: PropTypes.any,
    values: PropTypes.any,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    errors: PropTypes.any,
    touched: PropTypes.any,
  };
  
  Field.defaultProps = {
    fieldType: 'text',
    fieldName: '',
    gridSize: 12,
    startIcon: null,
  };
  
  export default Field;