import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Input,
  Typography,
} from '@material-ui/core';
import CardTemplate from './CardTemplate';

const Email = ({ stage, nextStage, values, errors, touched, handleChange, handleBlur }) => {
  return (
    <CardTemplate
      stage={stage} 
      minStage={1}
      stageId="#stage1"
      stageAvatar="1" 
      stageTitle="Email Address"
      subHeader="Question 1 of 9"
      nextStage={nextStage}
      nextStageDisabled={values.email == ''}
      nextTitle="Save"
    >
      <FormControl style={{ display: 'inherit' }}>
        <Typography variant="body1">What&apos;s your personal email address?</Typography>
        <Input type="text" placeholder="Email" autoComplete="email" 
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          style={{ width: '100%' }} />
        {errors.email && touched.email && errors.email}
        <Typography variant="subtitle2" style={{ fontSize: 'small', marginTop: '0.4em' }}>We suggest using a personal email address to seperate between supporting a business as a business and supporting them as a consumer.</Typography>
      </FormControl>
    </CardTemplate>
  );
};

Email.propTypes = {
  stage: PropTypes.number,
  nextStage: PropTypes.func,
  values: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func
};

Email.defaultProps = {
};

export default Email;
