import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import mainStyle from '../../style/default';

const EnhancedAvatar = ({ name, image }) => {
  const main = mainStyle()();
  const firstLetter = (name && name[0]) ?? ' ';

  // <Avatar alt={name} src={image} className={main.avatarDirectoryResult} aria-label={name}>
  // <Avatar alt={name} src={image} className={main.basicAvatar} aria-label={name}>
  // <Avatar alt={name} src={image} className={main.cardAvatar} aria-label={name}>

  return (
    <Avatar alt={name} src={image} className={main.cardAvatar} aria-label={name}>
      {firstLetter}
    </Avatar>
  );
};

EnhancedAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
};

EnhancedAvatar.defaultProps = {
  name: null,
  image: null,
};

export default EnhancedAvatar;
